.Candidates {
  width: 100%;
}
.Candidates__col__heading {
  min-height: 50px;
}
.Candidates__heading {
  font-family: "Red Hat Text";
  color: var(--dark);
  line-height: 72px;
  font-size: 60px;
  font-weight: 900;
}
.Candidates__detail {
  width: 75%;
  font-family: "Red Hat Text";
  color: var(--dark);
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;
}
.Candidates--button {
  outline: none;
  border: 0px solid;
  height: 40px;
  border-radius: 40px;
  width: 185px;
  background: var(--secondary);
}
.Candidates--button--Link {
  font-family: "Red Hat Text";
  color: var(--dark);
  font-weight: 500;
  font-size: 18px;
}
.Candidates--button--Link:hover {
  color: var(--dark);
}
.Candidates__card {
  max-width: 320px;
  width: 100%;
  border-radius: 10px;
  background: var(--secondary);
  box-shadow: 0px 15px 43px rgba(160, 168, 189, 0.3);
  margin-bottom: 40px;
}
.Candidates__card__content {
  border-radius: 10px;
  background: var(--light);
  font-family: "Red Hat Text";
  width: 100%;
  min-height: 569px;
  height: 103%;
}
.Candidates__card__content__index {
  font-family: "Red Hat Text";
  color: var(--main);
  font-weight: 500;
  font-size: 22px;
}
.Candidates__card__content__heading {
  font-family: "Red Hat Text";
  color: var(--main);
  font-weight: 500;
  font-size: 22px;
}
.Candidates__card__content__detail {
  font-family: "Red Hat Text";
  color: var(--dark);
  font-weight: 400;
  font-size: 16px;
}
.Candidates__card__content--ul--li {
  font-family: "Red Hat Text";
  color: var(--dark);
  font-weight: 400;
  font-size: 16px;
}

@media only screen and (max-width: 1199px) {
  .Candidates__card {
    max-width: 100%;
  }
  .Candidates__card__content {
    min-height: 534px;
  }
}
@media only screen and (max-width: 991px) {
  .Candidates__card {
    max-width: 320px;
  }
  .Candidates__col {
    max-width: 250px;
  }
}
@media only screen and (max-width: 767px) {
  .Candidates__heading {
    font-size: 50px;
    line-height: 65px;
  }
  .Candidates__detail {
    font-size: 18px;
    line-height: 28px;
  }
  .Candidates__col {
    max-width: 320px;
  }
  .Candidates__col__heading {
    min-height: 30px;
  }
}
@media only screen and (max-width: 525px) {
  .Candidates__heading {
    font-size: 42px;
    line-height: 55px;
  }
  .Candidates__detail {
    font-size: 18px;
    line-height: 28px;
  }
}
@media only screen and (max-width: 445px) {
  .Candidates__heading {
    font-size: 35px;
    line-height: 45px;
  }
  .Candidates__detail {
    width: 100%;
    font-size: 17px;
    line-height: 27px;
  }
}
@media only screen and (max-width: 378px) {
  .Candidates__detail {
    font-size: 16px;
    line-height: 26px;
  }
}/*# sourceMappingURL=style.css.map */