@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
.Navbar {
  position: fixed;
  top: 0;
  transition: all 0.2s ease;
  font-family: "Red Hat Text";
  z-index: 999;
  width: 100%;
}
.Navbar-light {
  background: var(--light) !important;
}
.Navbar-grey {
  background: var(--grey) !important;
}
.Navbar__brand {
  max-width: 150px;
}
.Navbar__brand__img {
  max-width: 150px;
  width: 100%;
}
.Navbar__right__nav__item__link {
  color: var(--dark);
  font-family: "Red Hat Text";
  font-weight: 500;
  transition: all 0.5s ease;
}
.Navbar__right__nav__item__link:hover {
  color: var(--main);
}
.Navbar__right__btn {
  font-family: "Red Hat Text";
  font-weight: 500;
  border: 1px solid var(--secondary);
  outline: none !important;
  color: var(--dark);
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
  line-height: 27px;
  color: var(--dark);
}
.Navbar__right__btn:hover {
  color: var(--dark);
}
.Navbar__right__popup {
  height: 40px;
  cursor: pointer;
  font-family: "Red Hat Text";
  color: var(--dark);
  font-weight: 500;
  position: relative;
  background: var(--secondary);
  border-radius: 5px;
  border: 2px solid var(--secondary);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-top: 7px;
}
.Navbar__right__popup__menu {
  position: absolute;
  bottom: -125px;
  border-radius: 5px;
  left: 0;
  box-shadow: 0px 15px 43px rgba(160, 168, 189, 0.3);
  box-sizing: border-box;
  transform: scale(0);
  background: var(--light);
}
.Navbar__right__popup__menu__item__link {
  font-family: "Red Hat Text";
  color: var(--dark);
  font-weight: 500;
  transition: all 0.2s ease;
}
.Navbar__right__popup__menu__item__link:hover {
  color: var(--main);
}

.burger-icon {
  color: var(--main);
}/*# sourceMappingURL=style.css.map */