.Login
    margin-top: 95px
    background: url("../../Images/SignIn/sign-in right.png"), url("../../Images/SignIn/sign-in left.png")
    background-repeat: no-repeat, no-repeat
    background-position: right top, bottom left
    background-size: 190px, 250px
    &__form
        background: var(--secondary)
        box-shadow: 0px 15px 43px rgba(160,168,189,0.3)
        &__left
            position: relative
            background-color: var(--secondary)
            &__top
                position: absolute
                top: 0
                right: 0
                width: 250px
            &__bottom
                position: absolute
                bottom: 0
                left: 0
                width: 250px
            &__hero
                max-width: 300px
                width: 100% !important
                position: relative
                z-index: 4
            &__logo
                max-width: 200px
                width: 100%
                z-index: 88
            &__heading
                font-family: 'Red Hat Text'
                color: var(--light)
                font-size: 55px
        &__right
            background: var(--light)
            &__heading
                font-family: 'Red Hat Text'
                font-size: 60px
                &--secondary
                    color: var(--secondary)
                &--main
                    color: var(--main)
            &--form
                width: 75%
                &--input
                    border: 1px solid #D3D3D3
                    border-radius: 10px
                    width: 100%
                    &:focus
                        border: 1px solid #D3D3D3 !important
                        outline: none !important
                &--checkbox
                    &:focus
                        background: var(--main)
                &--label
                    font-family: 'Red Hat Text'
                &--small
                    font-family: 'Red Hat Text'
                    color: var(--dark)
                &--button
                    font-family: 'Red Hat Text'
                    outline: none !important
                    background: var(--secondary)
                    color: var(--dark)
                    border-radius: 5px
                    border: 0px solid
                    font-size: 18px
                    font-weight: 500
                &--button2
                    font-family: 'Red Hat Text'
                    outline: none !important
                    background: var(--light)
                    color: var(--dark)
                    border-radius: 10px
                    border: 0px solid
                    font-size: 16px
                    box-shadow: 0 1px 3px #dedede
                    &--img
                        max-width: 30px
                        width: 100%
                &__divider
                    position: relative
                    &--hr
                        max-width: 150px !important
                        width: 150 !important
                        max-height: 4px
                        background: var(--dark)
                    &--small
                        font-family: 'Red Hat Text'
                        color: var(--dark)
                    &::before
                        content: ""
                        position: absolute
                        max-width: 40%
                        width: 100%
                        height: 1px
                        background: #666666
                        left: 8px
                    &::after
                        content: ""
                        position: absolute
                        max-width: 40%
                        width: 100%
                        height: 1px
                        background: #666666
                        right: 8px
                &__para
                    color: var(--dark)
                    font-family: 'Red Hat Text'
                    &--Link
                        color: var(--secondary)
                        font-family: 'Red Hat Text'
                        &:hover
                            color: var(--secondary)
@media only screen and (max-width: 991px)
    .Login__form__right--form
        width: 100%
    .Login__form__left__hero
        max-width: 250px
    .Login__form__left__heading
        font-size: 50px
@media only screen and (max-width: 767px)
    .Login__form__left__hero
        max-width: 300px
    .Login__form__right--form
        width: 90%
@media only screen and (max-width: 430px)
    .Login__form__left__hero
        max-width: 250px
    .Login__form__left__heading
        font-size: 40px
    .Login__form__left__logo
        max-width: 150px
    .Login__form__right__heading
        font-size: 50px
    .Login
        background-size: 150px, 200px
