.Cards {
  background: var(--grey);
}
.Cards__heading {
  font-family: "Red Hat Text";
  color: var(--dark);
  font-weight: 900;
  line-height: 50px;
  font-size: 27px;
}
.Cards__heading-green {
  color: var(--main);
  font-weight: 600;
}
.Cards__container {
  width: 89% !important;
}
.Cards__card {
  background: var(--light);
  box-shadow: 0px 15px 43px rgba(160, 168, 189, 0.3);
  padding: 25px 33px !important;
  border-radius: 5px;
  min-height: 280px;
  width: 300px;
  position: relative;
}
.Cards__card__heading {
  font-family: "Red Hat Text";
  color: var(--dark);
}
.Cards__card__detail {
  font-family: "Red Hat Text";
  color: var(--dark);
}
.Cards__card__icon {
  color: var(--secondary);
  position: absolute;
  bottom: 15px;
  right: 20px;
  cursor: pointer;
}
.Cards__card__icon-minus {
  bottom: 9px;
  right: 13px;
}

@media only screen and (min-width: 1400px) {
  .Cards__card {
    width: 355px;
  }
}
@media only screen and (max-width: 1199px) {
  .Cards__container {
    width: 70% !important;
  }
}
@media only screen and (max-width: 991px) {
  .Cards__container {
    width: 100% !important;
  }
}/*# sourceMappingURL=style.css.map */