.Recruiting
    background: var(--grey)
    &__heading
        font-family: 'Red Hat Text'
        &--green
            color: var(--main)
    &__card
        background: var(--light)
        border-radius: 5px
        box-shadow: 0px 15px 43px rgba(160,168,189,0.3)
        padding: 0px 30px
        &__heading
            color: var(--main)
            font-family: 'Red Hat Text'
            font-size: 18px
        &__para
            color: var(--dark)
            font-family: 'Red Hat Text'
            &__title
                border-radius: 20px
                background: var(--greyOption)
                font-family: 'Red Hat Text'
                font-weight: 400
                line-height: 20px
        &--5
            height: 264px

@media only screen and (max-width: 1199px)
    .Recruiting__card--3
        height: 248px
    .Recruiting__card--5
        height: 311px
@media only screen and (max-width: 991px)
    .Recruiting__card--3
        height: auto
    .Recruiting__card--5
        height: auto
        padding-bottom: 10px
@media only screen and (max-width: 767px)
    .Recruiting
        padding-top: 35px        

@media only screen and (max-width: 345px)
    .Recruiting__card__heading
        font-size: 16px
        padding: 0px !important
