.Avideo {
  background: var(--grey) !important;
}
.Avideo__content {
  color: var(--dark);
  font-family: "Red Hat Text";
}
.Avideo__content__heading {
  font-size: 30px;
  font-family: "Red Hat Text";
  font-weight: 600;
}
.Avideo__content__para {
  font-size: 20px;
}
.Avideo--video {
  max-width: 100%;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .Avideo__content__para {
    font-size: 19px;
  }
}
@media only screen and (max-width: 575px) {
  .Avideo__content__para {
    font-size: 18px;
  }
}
@media only screen and (max-width: 360px) {
  .Avideo__content__para {
    font-size: 17px;
  }
}/*# sourceMappingURL=style.css.map */