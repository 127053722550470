.Video {
  background: var(--grey);
}
.Video__container {
  max-width: 100%;
}
.Video__container--video {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: 0px 15px 43px rgba(160, 168, 189, 0.3);
}/*# sourceMappingURL=style.css.map */