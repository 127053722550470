@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap')
.btn-close
    color: var(--dark)
.sidebar-popup
    bottom: -135px
.Sidebar__btn
    color: var(--dark)
    &:hover
        color: var(--dark)
.Sidebar-login
    background: var(--light)
.hr
    border: 0px solid var(--main)
    background: var(--main)
    width: 0px
    height: 2px
    opacity: 1
    transition: all 0.3s ease
.hr-focused
    width: 5px
    height: 5px
@media only screen and (max-width: 400px)
    .offcanvas
        overflow: visible
        height: 100% !important
@media only screen and (max-width: 292px)
    .offcanvas
        height: 100% !important
