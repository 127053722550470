.Team {
  background: var(--grey);
}
.Team__heading {
  font-size: 30px;
  font-family: "Red Hat Text";
  font-weight: 600;
}
.Team__card {
  max-width: 340px;
}
.Team__card--img {
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Team__card--bg {
  background: var(--light);
  box-shadow: 0px 15px 43px rgba(160, 168, 189, 0.3);
}
.Team__card__content__title1 {
  color: var(--main);
  font-family: "Red Hat Text";
  font-size: 20px;
  font-weight: 500;
}
.Team__card__content__title2 {
  color: #333333;
  font-family: "Red Hat Text";
  font-size: 18px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .Team__card--img {
    max-width: 300px;
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .Team__card__content__title {
    font-size: 19px;
  }
  .Team__card--img {
    max-width: 250px;
    width: 100%;
  }
  .Team__heading {
    line-height: 40px;
  }
}
@media only screen and (max-width: 324px) {
  .Team__heading {
    font-size: 27px;
  }
}
@media only screen and (max-width: 298px) {
  .Team__heading {
    font-size: 25px;
  }
}/*# sourceMappingURL=style.css.map */