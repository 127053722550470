.Banner {
  height: 560px;
}
.Banner__left__heading {
  font-size: 60px;
  line-height: 70px;
  color: var(--dark);
  font-family: "Red Hat Text";
  font-weight: 900;
}
.Banner__left--p {
  color: var(--dark);
  font-family: "Red Hat Text";
  font-size: 20px;
  line-height: 30px;
}
.Banner__left--button {
  font-family: "Red Hat Text";
  outline: none !important;
  background: var(--secondary);
  color: var(--dark);
  border-radius: 10px;
  border: 0px solid;
  font-size: 18px;
  width: 200px;
  height: 45px;
  font-weight: 500;
}
.Banner__left--button--Link {
  color: var(--dark);
}
.Banner__left--button--Link:hover {
  color: var(--dark);
}
.Banner--video {
  max-width: 400px !important;
  top: 0 !important;
}

@media only screen and (max-width: 767px) {
  .Banner--img {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: auto;
  }
}
@media only screen and (max-width: 991px) {
  .Banner__left--p {
    font-size: 20px;
  }
  .Banner {
    height: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .Banner__left--p {
    font-size: 18px;
    line-height: 28px;
  }
  .Banner__left__heading {
    font-size: 55px !important;
    line-height: 65px !important;
  }
}
@media only screen and (max-width: 360px) {
  .Banner__left--p {
    font-size: 16px;
    line-height: 25px;
  }
  .Banner__left__heading {
    font-size: 45px !important;
    line-height: 55px !important;
  }
}/*# sourceMappingURL=style.css.map */