.Contact
    background: var(--light)
    &__heading
        font-family: 'Red Hat Text'
        color: var(--dark)
        line-height: 72px
        font-size: 60px
        font-weight: 900
    &__detail
        font-family: 'Red Hat Text'
        color: var(--dark)
        font-size: 24px
        font-weight: 500
        line-height: 36px
    &--form
        width: 78%   
    &--select
        padding: 6px 7px !important 
        margin-top: 30px    
    &--input
        border: 1px solid #D3D3D3
        border-radius: 10px
        font-family: 'Red Hat Text' !important
        width: 100% !important
        margin-top: 30px
        padding: 11px 13px !important
        background: var(--light)
        &:focus
            border: 1px solid #D3D3D3 !important
            outline: none !important
            box-shadow: none !important
    &--textarea
        padding: 13px 13px !important
        margin-top: 30px !important
    &--button
        font-family: 'Red Hat Text'
        outline: none
        width: 100px !important
        height: 40px    
        color: var(--dark)
        margin-top: 27px !important
        border: 0px solid 
        background: var(--secondary) !important
        border-radius: 5px
        font-weight: 500
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0
input[type=number]
    -moz-appearance: textfield
@media only screen and (max-width: 1199px)    
    .Contact--form
        width: 90%
@media only screen and (max-width: 991px)    
    .Contact--form
        width: 100%   
    .Contact__detail
        font-size: 20px
        line-height: 32px       
@media only screen and (max-width: 575px)    
    .Contact__heading
        line-height: 60px
        font-size: 50px
    .Contact__detail
        font-size: 18px
        line-height: 32px   
    .Contact--form
        width: 90%  
@media only screen and (max-width: 455px)    
    .Contact__heading
        line-height: 50px
        font-size: 45px  
    .Contact--form
        width: 100%                  
