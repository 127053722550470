.Cards2 {
  background: var(--light);
}
.Cards2__text {
  font-family: "Red Hat Text";
  box-shadow: 0px 0px 0px rgba(160, 168, 189, 0.3) !important;
  background: var(--light) !important;
  border-radius: 0px !important;
}
.Cards2__text__heading {
  font-family: "Red Hat Text";
  font-weight: 900;
  font-size: 42px;
  line-height: 52px;
}
.Cards2__text__heading--green {
  color: var(--secondary);
}
.Cards2__container__card {
  padding-top: 35px !important;
  background: var(--light);
  max-width: 250px;
  width: 100%;
  min-height: 280px;
  border-radius: 5px;
  box-shadow: 0px 15px 43px rgba(160, 168, 189, 0.3);
}
.Cards2__container__card--img {
  max-width: 80px;
}
.Cards2__container__card__heading {
  font-family: "Red Hat Text";
  font-weight: 600;
}
.Cards2__container__card__detail {
  font-size: 15px;
  line-height: 22px;
}/*# sourceMappingURL=style.css.map */