.EVideo__content__detail {
  font-family: "Red Hat Text";
  color: var(--dark);
  font-size: 20px;
  line-height: 30px;
}
.EVideo__heading {
  font-family: "Red Hat Text";
  color: var(--dark);
  line-height: 45px;
  font-size: 35px;
  font-weight: 900;
}
.EVideo--img {
  max-width: 100%;
  -o-object-fit: fill;
     object-fit: fill;
}

.bg-grey {
  background: var(--grey);
}

@media only screen and (min-width: 1400px) {
  .EVideo__heading {
    line-height: 60px !important;
    font-size: 50px !important;
  }
  .EVideo__content__detail {
    font-size: 21px !important;
    line-height: 35px !important;
  }
}
@media only screen and (max-width: 368px) {
  .EVideo__heading {
    line-height: 40px !important;
    font-size: 30px !important;
  }
  .EVideo__content__detail {
    font-size: 18px !important;
    line-height: 28px !important;
  }
}/*# sourceMappingURL=style.css.map */