.Avideo
    background: var(--grey) !important
    &__content
        color: var(--dark)
        font-family: 'Red Hat Text'
        &__heading
            font-size: 30px
            font-family: 'Red Hat Text'
            font-weight: 600
        &__para
            font-size: 20px
    &--video
        max-width: 100%
        width: 100%
@media only screen and (max-width: 767px)
    .Avideo__content__para
        font-size: 19px
@media only screen and (max-width: 575px)
    .Avideo__content__para
        font-size: 18px
@media only screen and (max-width: 360px)
    .Avideo__content__para
        font-size: 17px
