.Content__text {
  width: 85%;
}
.Content__text__heading {
  font-family: "Red Hat Text";
  color: var(--dark);
  line-height: 67px;
  font-size: 47px;
  font-weight: 900;
}
.Content__text__heading--green {
  color: var(--secondary);
}
.Content__text__heading--green--break {
  white-space: nowrap;
}
.Content__text__detail {
  font-family: "Red Hat Text";
  color: var(--dark);
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}
.Content__text--button {
  font-family: "Red Hat Text";
  outline: none;
  max-width: 250px !important;
  width: 100%;
  height: 45px;
  margin-top: 17px !important;
  border: 0px solid;
  background: var(--secondary) !important;
  border-radius: 5px;
  font-weight: 500;
}
.Content__text--button--Link {
  font-size: 18px;
  color: var(--dark);
  font-family: "Red Hat Text";
}
.Content__text--button--Link:hover {
  color: var(--dark);
}

@media only screen and (min-width: 1400px) {
  .Cards1__container__heading {
    width: 90%;
  }
  .Cards1__container {
    width: 1170px !important;
  }
  .Cards2__container {
    width: 1170px;
  }
  .Cards2__container__card {
    max-width: 347px !important;
  }
  .Cards2__container__card__detail {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .Cards2__text__heading {
    font-size: 51px !important;
    line-height: 63px !important;
  }
}
@media only screen and (max-width: 1199px) {
  .Content__text__heading {
    line-height: 63px;
    font-size: 44px;
  }
  .Content__text__detail {
    font-size: 23px;
    line-height: 33px;
  }
  .Cards2__container {
    width: 568px;
  }
}
@media only screen and (max-width: 991px) {
  .Content__text {
    width: 568px;
  }
  .Content__text__heading {
    line-height: 60px;
    font-size: 42px;
  }
  .Cards2__container {
    width: 568px;
  }
}
@media only screen and (max-width: 659px) {
  .Content__text {
    width: 95%;
  }
  .Content__text__heading {
    line-height: 60px;
    font-size: 42px;
  }
  .Cards1__container {
    max-width: 347px;
  }
  .Cards1__container__card {
    max-width: 347px !important;
  }
  .Cards2__container {
    max-width: 347px;
  }
  .Cards2__text__heading {
    font-size: 37px !important;
    line-height: 45px !important;
  }
  .Cards2__container__card {
    max-width: 347px !important;
  }
  .Cards2__container__card__detail {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .Cards2__text__heading {
    font-size: 45px !important;
    line-height: 55px !important;
  }
}
@media only screen and (max-width: 630px) {
  .Content__text__heading {
    line-height: 60px;
    font-size: 40px;
  }
  .Content__text__detail {
    font-size: 22px;
    line-height: 35px;
  }
}
@media only screen and (max-width: 590px) {
  .Content__text__heading {
    line-height: 57px;
    font-size: 38px;
  }
}
@media only screen and (max-width: 517px) {
  .Content__text__heading {
    line-height: 50px;
    font-size: 35px;
  }
  .Cards2__text__heading {
    font-size: 32px !important;
    line-height: 45px !important;
  }
}
@media only screen and (max-width: 450px) {
  .Content__text__heading {
    line-height: 45px;
    font-size: 32px;
  }
  .Content__text__detail {
    font-size: 20px;
    line-height: 30px;
  }
  .Content__text__heading--green--break {
    white-space: normal;
  }
}
@media only screen and (max-width: 327px) {
  .Content__text__heading {
    line-height: 40px;
    font-size: 30px;
  }
  .Content__text__detail {
    font-size: 18px;
    line-height: 27px;
  }
  .Cards2__text__heading {
    font-size: 30px !important;
    line-height: 40px !important;
  }
}/*# sourceMappingURL=style.css.map */