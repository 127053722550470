.Cards1 {
  background: var(--light);
}
.Cards1__container__heading {
  font-size: 43px;
  line-height: 53px;
}
.Cards1__container__heading--green {
  color: var(--main);
}
.Cards1__container__card {
  background: var(--light);
  max-width: 250px;
  width: 100%;
  min-height: 250px;
  border-radius: 5px;
  box-shadow: 0px 15px 43px rgba(160, 168, 189, 0.3);
}
.Cards1__container__card--img {
  max-width: 100px;
}
.Cards1__container__card__heading {
  font-family: "Red Hat Text";
  font-weight: 600;
}

@media only screen and (max-width: 659px) {
  .Cards1__container__heading {
    font-size: 38px !important;
    line-height: 48px !important;
  }
}
@media only screen and (max-width: 511px) {
  .Cards1__container__heading {
    font-size: 35px !important;
    line-height: 43px !important;
  }
}
@media only screen and (max-width: 351px) {
  .Cards1__container__heading {
    font-size: 32px !important;
    line-height: 40px !important;
  }
}/*# sourceMappingURL=style.css.map */