.Top
    background: var(--privacy)
    &__navbar
        &__right
            font-family: 'Red Hat Text'
            color: #505E6D
            font-size: 18px
            font-weight: 500
            &:hover
                color: #505E6D
    &__header
        &__heading
            font-family: 'Red Hat Text'
            color: var(--privacyText)
            font-size: 60px
            font-weight: 700
            &--weight
                font-weight: normal
    &__list
        &__item
            &--Link
                font-family: 'Red Hat Text'
                color: var(--privacyText)
                font-size: 16px
                &--bold
                    font-weight: 500
                &:hover
                    color: var(--privacyText)
@media only screen and (max-width: 750px)
    .Top__header__heading
        font-size: 55px
@media only screen and (max-width: 650px)
    .Top__header__heading
        font-size: 50px
@media only screen and (max-width: 575px)
    .Top__header__heading
        font-size: 45px
    .Top__list__item--mid
        margin-left: 3rem
        margin-right: 3rem
@media only screen and (max-width: 475px)
    .Top__header__heading
        font-size: 40px
@media only screen and (max-width: 375px)
    .Top__header__heading
        font-size: 35px
    .Top__list__item--mid
        margin-left: 2.5rem
        margin-right: 2.5rem    
@media only screen and (max-width: 309px)
    .Top__header__heading
        font-size: 33px
    .Top__list__item--mid
        margin-left: 2.2rem
        margin-right: 2.2rem 
@media only screen and (max-width: 294px)
    .Top__header__heading
        font-size: 30px
    .Top__list__item--mid
        margin-left: 2rem
        margin-right: 2rem     
