.Hero
    &__content
        // height: 80vh !important
        &__heading
            color: var(--dark)
            font-weight: 900
            font-family: 'Red Hat Text'
            font-size: 60px
            line-height: 70px
        &__para
            font-family: 'Red Hat Text'
            font-size: 25px
            line-height: 35px
        &--img
            max-width: 460px
            width: 100%
            max-height: 460px
            height: 100%
        &--button
            font-family: 'Red Hat Text'
            outline: none !important
            background: var(--secondary)
            color: var(--dark)
            border-radius: 10px
            border: 0px solid
            width: 180px
            height: 48px
            font-weight: 500
            &__link
                font-size: 18px
                line-height: 27px
                color: var(--dark)
                &:hover
                    color: var(--dark)
@media only screen and (min-width: 1400px)
    .Hero__content__heading
        font-size: 70px
        line-height: 85px
    .Hero__content__para
        font-size: 28px
        line-height: 37px
@media only screen and (max-width: 991px)
    .Hero__content__heading
        font-size: 50px
        line-height: 60px
    .Hero__content__para
        font-size: 25px
        line-height: 34px
@media only screen and (max-width: 450px)
    .Hero__content__heading
        font-size: 45px
        line-height: 50px
    .Hero__content__para
        font-size: 20px
        line-height: 30px       
@media only screen and (max-width: 439px)
    .Hero__content__heading
        font-size: 40px
        line-height: 50px
@media only screen and (max-width: 394px)
    .Hero__content__heading
        font-size: 35px
        line-height: 45px
    .Hero__content__para
        font-size: 18px
        line-height: 26px
    .Hero__content--button__link
        font-size: 17px
        line-height: 26px
@media only screen and (max-width: 327px)
    .Hero__content__heading
        font-size: 30px
        line-height: 40px
@media only screen and (max-width: 292px)
    .Hero__content__heading
        font-size: 28px
        line-height: 35px
