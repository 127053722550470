.Signup
    background: var(--grey)
    &__left
        &--img
            max-width: 400px
            width: 100%
            max-height: 100%
            height: auto
    &__right
        position: relative
        &__heading
            font-family: 'Red Hat Text'
            color: var(--dark)
            line-height: 72px
            font-size: 60px
        &__heading2
            font-family: 'Red Hat Text'
            color: var(--dark)
            font-size: 22px
            line-height: 26px
        &__para
            font-family: 'Red Hat Text'
            color: var(--greyText)
            font-size: 24px
            font-weight: 500
            line-height: 36px
        &--select
            color: var(--dark)
            font-family: 'Red Hat Text'
            font-size: 15px
        &--select2
            width: 50% !important
        &--select5
            width: 50% !important
            &-container
                display: flex
        &__password
            &--input
                border-top-left-radius: 5px
                border-bottom-left-radius: 5px
                border-left: 1px solid #B7C93B !important
                border-top: 1px solid #B7C93B !important
                border-bottom: 1px solid #B7C93B !important
                border-right: 0px solid #B7C93B !important
                padding: 7px 10px
                color: var(--dark)
                font-size: 16px
                font-family: 'Red Hat Text'
                width: 90% !important
                font-weight: 500
                &:focus
                    border-left: 1px solid #B7C93B !important
                    border-top: 1px solid #B7C93B !important
                    border-bottom: 1px solid #B7C93B !important
                    border-right: 0px solid #B7C93B !important
                    outline: none !important
                &--Link
                    border-left: 0px solid #B7C93B !important
                    border-top: 1px solid #B7C93B !important
                    border-bottom: 1px solid #B7C93B !important
                    border-right: 1px solid #B7C93B !important
                    background: var(--light) !important
                    border-top-right-radius: 5px
                    border-bottom-right-radius: 5px
                    &--img
                        width: 20px
        &__small
            color: #7a7a7a
            font-family: 'Red Hat Text'
            font-weight: 500
            white-space: nowrap
        &--span
            color: #7a7a7a
            font-family: 'Red Hat Text'
            font-weight: 500
            &--Link
                color: var(--dark)
                &:hover
                    color: var(--dark)
        &__detail
            color: var(--dark)
            font-family: 'Red Hat Text'
            font-weight: 500
            font-size: 14px
            line-height: 21px
        &--button
            font-family: 'Red Hat Text'
            outline: none !important
            background: var(--secondary)
            color: var(--dark)
            border-radius: 5px
            border: 0px solid
            font-size: 18px
            font-weight: 500
            box-shadow: 0px 15px 43px rgba(160,168,189,0.3)
            cursor: pointer
            &-option
                background: var(--light)
                border: 1px solid var(--secondary)
                font-size: 17px
            &-upload
                font-size: 16px
            &--img
                width: 17px
            &--Link
                color: var(--dark)
                font-family: 'Red Hat Text'
                &:hover
                    color: var(--dark)
        &__bottom
            color: var(--dark)
            font-family: 'Red Hat Text'
            position: absolute
            bottom: 15px
            left: 25px
            font-weight: 500
            &--Link
                color: var(--secondary)
                white-space: nowrap
                &:hover
                    color: var(--secondary)
.image-col
    min-height: 540px
    height: auto
.inputfile
    width: 0.1px
    height: 0.1px
    opacity: 0
    overflow: hidden
    position: absolute
    z-index: -1
.inputfile + label
    cursor: pointer


@media only screen and (max-width: 1199px)
    .Signup__right__heading
        font-size: 45px
        line-height: 60px
    .Signup__right__para
        font-size: 20px
        line-height: 30px
@media only screen and (max-width: 991px)
    .Signup__right__heading
        font-size: 33px
        line-height: 50px
    .Signup__right__para
        font-size: 18px
        line-height: 25px
    .Signup__right--select2
        width: 75% !important
    .Signup__right__bottom
        left: 5px
        bottom: 30px
    .Signup__right__heading2
        font-size: 18px
        line-height: 24px
@media only screen and (max-width: 767px)
    .Signup__right__bottom
        left: 28px
        bottom: 0px
@media only screen and (max-width: 443px)
    .Signup__right__heading
        font-size: 30px
    .Signup__right__heading2
        font-size: 16px
        line-height: 20px
    .image-col
        min-height: 300px
        height: auto
@media only screen and (max-width: 376px)
    .Signup__right__heading
        font-size: 27px
        white-space: nowrap
    .Signup__right__para
        font-size: 16px
    .Signup__right--button-option
        font-size: 16px
@media only screen and (max-width: 334px)
    .Signup__right__heading
        font-size: 25px
        font-weight: 600
@media only screen and (max-width: 300px)
    .Signup__right__heading
        font-size: 22px
        font-weight: 600
    .Signup__right--button-upload
        font-size: 14px
@media only screen and (max-width: 664px)
    .Signup__right--select2
        width: 100% !important
@media only screen and (max-width: 575px)
    .Signup__right--select2
        width: 70% !important
@media only screen and (max-width: 410px)
    .Signup__right--select2
        width: 100% !important
    .Signup__right--select5
        width: 100% !important
    .Signup__right--select5-container
        display: block !important
