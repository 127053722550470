.Job
    background: var(--grey)
    &--row
        border-radius: 5px
        box-shadow: 0px 15px 43px rgba(160,168,189,0.3) 
        background: var(--light)
    &__content
        padding: 0px 30px
        &__heading
            font-family: 'Red Hat Text'
        &__row
            &__img
                max-height: 62px
                max-width: 62px
                width: 100%
            &__heading
                font-family: 'Red Hat Text'
            &__para
                font-family: 'Red Hat Text'
        &--button
            font-family: 'Red Hat Text'
            outline: none !important
            background: var(--secondary)
            color: var(--dark)
            border-radius: 10px
            border: 0px solid
            width: 160px
            height: 45px
            font-weight: 500
            &__link
                color: var(--dark)
                font-size: 18px
                line-height: 27px
                white-space: nowrap
                &:hover
                    color: var(--dark)
    &--col
        overflow: hidden
        position: relative
    &--video
        max-width: 100%
        position: absolute
        left: 0px
        top: -20px
    &--video2
        max-width: 100%
        width: 100%
@media only screen and (min-width: 1400px)
    .Job--video
        top: -60px
@media only screen and (max-width: 1199px)
    .Job--video
        max-width: 100%
        top: 10%
@media only screen and (max-width: 991px)
    .Job--video2
        max-width: 90%
        top: 10%        
@media only screen and (max-width: 461px)
    .Job__content__row__img
        max-width: 50px
        max-height: 50px
@media only screen and (max-width: 344px)
    .Job__content--button__link
        font-size: 17px
        line-height: 26px         
