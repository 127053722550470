.Login {
  margin-top: 95px;
  background: url("../../Images/SignIn/sign-in right.png"), url("../../Images/SignIn/sign-in left.png");
  background-repeat: no-repeat, no-repeat;
  background-position: right top, bottom left;
  background-size: 190px, 250px;
}
.Login__form {
  background: var(--secondary);
  box-shadow: 0px 15px 43px rgba(160, 168, 189, 0.3);
}
.Login__form__left {
  position: relative;
  background-color: var(--secondary);
}
.Login__form__left__top {
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
}
.Login__form__left__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 250px;
}
.Login__form__left__hero {
  max-width: 300px;
  width: 100% !important;
  position: relative;
  z-index: 4;
}
.Login__form__left__logo {
  max-width: 200px;
  width: 100%;
  z-index: 88;
}
.Login__form__left__heading {
  font-family: "Red Hat Text";
  color: var(--light);
  font-size: 55px;
}
.Login__form__right {
  background: var(--light);
}
.Login__form__right__heading {
  font-family: "Red Hat Text";
  font-size: 60px;
}
.Login__form__right__heading--secondary {
  color: var(--secondary);
}
.Login__form__right__heading--main {
  color: var(--main);
}
.Login__form__right--form {
  width: 75%;
}
.Login__form__right--form--input {
  border: 1px solid #D3D3D3;
  border-radius: 10px;
  width: 100%;
}
.Login__form__right--form--input:focus {
  border: 1px solid #D3D3D3 !important;
  outline: none !important;
}
.Login__form__right--form--checkbox:focus {
  background: var(--main);
}
.Login__form__right--form--label {
  font-family: "Red Hat Text";
}
.Login__form__right--form--small {
  font-family: "Red Hat Text";
  color: var(--dark);
}
.Login__form__right--form--button {
  font-family: "Red Hat Text";
  outline: none !important;
  background: var(--secondary);
  color: var(--dark);
  border-radius: 5px;
  border: 0px solid;
  font-size: 18px;
  font-weight: 500;
}
.Login__form__right--form--button2 {
  font-family: "Red Hat Text";
  outline: none !important;
  background: var(--light);
  color: var(--dark);
  border-radius: 10px;
  border: 0px solid;
  font-size: 16px;
  box-shadow: 0 1px 3px #dedede;
}
.Login__form__right--form--button2--img {
  max-width: 30px;
  width: 100%;
}
.Login__form__right--form__divider {
  position: relative;
}
.Login__form__right--form__divider--hr {
  max-width: 150px !important;
  width: 150 !important;
  max-height: 4px;
  background: var(--dark);
}
.Login__form__right--form__divider--small {
  font-family: "Red Hat Text";
  color: var(--dark);
}
.Login__form__right--form__divider::before {
  content: "";
  position: absolute;
  max-width: 40%;
  width: 100%;
  height: 1px;
  background: #666666;
  left: 8px;
}
.Login__form__right--form__divider::after {
  content: "";
  position: absolute;
  max-width: 40%;
  width: 100%;
  height: 1px;
  background: #666666;
  right: 8px;
}
.Login__form__right--form__para {
  color: var(--dark);
  font-family: "Red Hat Text";
}
.Login__form__right--form__para--Link {
  color: var(--secondary);
  font-family: "Red Hat Text";
}
.Login__form__right--form__para--Link:hover {
  color: var(--secondary);
}

@media only screen and (max-width: 991px) {
  .Login__form__right--form {
    width: 100%;
  }
  .Login__form__left__hero {
    max-width: 250px;
  }
  .Login__form__left__heading {
    font-size: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .Login__form__left__hero {
    max-width: 300px;
  }
  .Login__form__right--form {
    width: 90%;
  }
}
@media only screen and (max-width: 430px) {
  .Login__form__left__hero {
    max-width: 250px;
  }
  .Login__form__left__heading {
    font-size: 40px;
  }
  .Login__form__left__logo {
    max-width: 150px;
  }
  .Login__form__right__heading {
    font-size: 50px;
  }
  .Login {
    background-size: 150px, 200px;
  }
}/*# sourceMappingURL=style.css.map */