.signup
    &__left
        &--img
            position: relative
            max-width: 100%
            z-index: 88
    &__form
        box-shadow: 0px 15px 43px rgba(160,168,189,0.3)
        &__head
            font-family: 'Red Hat Text'
            &--h2
                font-family: 'Red Hat Text'
                color: var(--main)
            &--p
                font-family: 'Red Hat Text'
                color: var(--secondary)
        &--small-dark
            color: var(--dark)
            font-family: 'Red Hat Text'
        &--small-light
            color: #616161
            font-family: 'Red Hat Text'
        &--select
            color: var(--dark)
            font-family: 'Red Hat Text'
            font-size: 15px
        &--input
            border-radius: 10px
            border: 1px solid #D3D3D3
            padding: 7px 10px
            color: var(--dark)
            font-size: 15px
            font-family: 'Red Hat Text'
            &:focus
                border: 1px solid #D3D3D3 !important
                outline: none !important
        &--p
            color: var(--dark)
            font-family: 'Red Hat Text'
            font-size: 15px
            &--Link
                color: var(--secondary)
                text-decoration: none
                &:hover
                    color: var(--secondary)
                    text-decoration: none
        &--button
            font-family: 'Red Hat Text'
            outline: none !important
            background: var(--secondary)
            color: var(--dark)
            border-radius: 5px
            border: 0px solid
            font-size: 18px
            font-weight: 500
@media only screen and (max-width: 350px)
    .signIn__form--select
        font-size: 14px
