.Policy__container {
  max-width: 750px;
  width: 100%;
  color: var(--privacyText);
  font-family: "Red Hat Text";
}
.Policy__container--h3 {
  font-size: 22px;
}
.Policy__container--h6 {
  font-size: 15px;
  line-height: 25px;
}
.Policy__container__para {
  font-size: 14px;
  line-height: 20px;
}
.Policy__container__para--Link {
  text-decoration: none;
  color: var(--secondary);
}
.Policy__container__para--Link:hover {
  text-decoration: underline;
  color: var(--secondary);
}
.Policy__container__list__item {
  font-size: 14px;
  line-height: 20px;
}
.Policy__container__list__item--Link {
  color: var(--secondary);
}
.Policy__container__list__item--Link:hover {
  color: var(--secondary);
}
.Policy__container__list__item--bold {
  font-weight: 600;
}/*# sourceMappingURL=style.css.map */