.Form {
  background: var(--grey);
}
.Form__heading {
  font-family: "Red Hat Text";
  color: var(--dark);
  line-height: 72px;
  font-size: 60px;
  font-weight: 900;
}
.Form__detail {
  font-family: "Red Hat Text";
  color: var(--dark);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.Form--form {
  width: 78%;
}
.Form--form--button {
  font-family: "Red Hat Text";
  outline: none;
  max-width: 250px !important;
  width: 100%;
  height: 45px;
  color: var(--dark);
  margin-top: 30px !important;
  border: 0px solid;
  background: var(--secondary) !important;
  border-radius: 5px;
  font-weight: 500;
  font-size: 18px;
}
.Form--form--button--Link {
  color: var(--dark);
  font-family: "Red Hat Text";
}
.Form--form--button--Link:hover {
  color: var(--dark);
}

@media only screen and (max-width: 991px) {
  .Form--form {
    width: 100%;
  }
  .Form__heading {
    line-height: 60px;
    font-size: 50px;
  }
  .Form__detail {
    font-size: 20px;
    line-height: 32px;
  }
  .Cards__heading-green {
    font-size: 30px;
  }
}
@media only screen and (max-width: 1199px) {
  .Form--form {
    width: 90%;
  }
}
@media only screen and (max-width: 755px) {
  .Form__heading {
    line-height: 55px;
    font-size: 45px;
  }
  .Form__detail {
    font-size: 20px;
    line-height: 32px;
  }
  .Cards__heading-green {
    font-size: 28px;
  }
}
@media only screen and (max-width: 650px) {
  .Form__heading {
    line-height: 55px;
    font-size: 42px;
  }
  .Form__detail {
    font-size: 18px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 575px) {
  .Form__heading {
    line-height: 50px;
    font-size: 37px;
  }
  .Form__detail {
    font-size: 18px;
    line-height: 27px;
  }
  .Form--form {
    width: 90%;
  }
  .Cards__heading {
    line-height: 45px !important;
    font-size: 30px !important;
  }
}
@media only screen and (max-width: 467px) {
  .Form__heading {
    line-height: 45px;
    font-size: 34px;
  }
  .Form__detail {
    font-size: 17px;
    line-height: 25px;
  }
  .Form--form {
    width: 100%;
  }
}
@media only screen and (max-width: 425px) {
  .Form__heading {
    line-height: 52px;
    font-size: 42px;
  }
  .Form__detail {
    font-size: 18px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 377px) {
  .Form__heading {
    line-height: 55px;
    font-size: 45px;
  }
  .Cards__heading {
    line-height: 40px !important;
    font-size: 27px !important;
  }
}
@media only screen and (max-width: 342px) {
  .Form__heading {
    line-height: 50px;
    font-size: 40px;
  }
}
@media only screen and (max-width: 319px) {
  .Form__heading {
    line-height: 45px;
    font-size: 35px;
  }
  .Cards__heading {
    line-height: 40px !important;
    font-size: 30px !important;
  }
}/*# sourceMappingURL=style.css.map */