@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
.Footer {
  background: var(--light) !important;
}
.Footer__para {
  font-family: "Red Hat Text";
}
.Footer__heading {
  font-family: "Red Hat Text";
}
.Footer__menu__item__link {
  font-family: "Red Hat Text";
  color: var(--dark);
  font-weight: 500;
}
.Footer__menu__item__link:hover {
  color: var(--main);
}
.Footer__container--div {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: #1877F2;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.Footer__container--div__icon {
  border-radius: 50%;
  background: var(--light);
  color: #1877F2;
}
.Footer__container--div:hover {
  background: var(--secondary);
}
.Footer__container--div:hover .Footer__container--div__icon {
  color: var(--secondary);
}
.Footer__container--div2 {
  background: #55ACEE;
}
.Footer__container--div2__icon2 {
  color: var(--light);
}
.Footer__container--div3 {
  background: #2764AC;
}
.Footer__container--div3__icon3 {
  color: var(--light);
}
.Footer__container--div4 {
  background: #C32AA3;
}
.Footer__container--div4__icon4 {
  color: var(--light);
}
.Footer__container--div5 {
  background: #BB0000;
}
.Footer__container--div5__icon5 {
  color: var(--light);
}
.Footer__copyright__text {
  font-family: "Red Hat Text";
  color: var(--dark);
}
.Footer__copyright__list__item--Link {
  color: var(--dark);
}
.Footer__copyright__list__item--Link:hover {
  color: var(--dark);
}/*# sourceMappingURL=style.css.map */