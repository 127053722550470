.Cards
    background: var(--grey)
    position: relative
    &__content
        margin-top: -5rem
        background: var(--light) 
        border-radius: 5px
        box-shadow: 0px 15px 43px rgba(160,168,189,0.3) 
        font-family: 'Red Hat Text'
        padding: 0px 30px
        height: 235px
        font-size: 17px
        &__heading   
            color: var(--main)
        &__para
            color: var(--dark) 
@media only screen and (max-width: 1199px)
    .Cards__content
        height: 280px  
@media only screen and (max-width: 991px)
    .Cards__content
        height: 330px         
@media only screen and (max-width: 767px)
    .Cards__content
        height: auto
    .card1
        margin-bottom: 55px !important 
    .card2
        margin-bottom: 55px !important   
    .card3
        margin-bottom: 10px !important 
@media only screen and (max-width: 575px) 
    .card3
        margin-bottom: 0px !important           
