.Pricing__heading {
  font-family: "Red Hat Text";
  color: var(--dark);
  line-height: 72px;
  font-size: 55px;
  font-weight: 900;
}
.Pricing__detail {
  font-family: "Red Hat Text";
  color: var(--dark);
  line-height: 32px;
  font-size: 24px;
  font-weight: 500;
}
.Pricing-container {
  position: relative;
}
.Pricing--select {
  position: absolute;
  top: 15px;
  right: 0;
}
.Pricing--select--cursor {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Pricing--select--option {
  cursor: pointer;
  font-weight: 400;
  font-family: "Red Hat Text";
  color: var(--dark);
  transition: all 0.3s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Pricing--select--option:hover {
  background: var(--main);
  color: var(--light);
}
.Pricing--select--div {
  position: relative;
  font-family: "Red Hat Text";
  color: var(--dark);
  font-weight: 600;
  font-size: 15px;
}
.Pricing--select--div--ul {
  position: absolute;
  left: 0px;
  top: 29px;
  z-index: 10;
  box-shadow: 0px 15px 43px rgba(160, 168, 189, 0.3);
  background: var(--light);
  width: 95%;
  border-radius: 5px;
}
.Pricing__group {
  border-radius: 50px !important;
}
.Pricing__group__btn {
  font-family: "Red Hat Text";
}
.Pricing__group__btn1 {
  font-size: 16px !important;
  height: 45px;
  width: 110px;
  font-weight: 600 !important;
  border: 2px solid var(--main) !important;
  border-top-left-radius: 25px !important;
  border-bottom-left-radius: 25px !important;
}
.Pricing__group__btn2 {
  height: 45px;
  border: 2px solid var(--main) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}
.Pricing__group__btn--small {
  font-family: "Red Hat Text";
  background: #F56C6C;
  font-size: 10px;
  padding: 3px 5px;
  border-radius: 5px;
  vertical-align: middle;
  display: inline-block;
  margin: -2px 10px 0;
  line-height: 1.3;
  letter-spacing: 0.3px;
  color: var(--light);
  font-weight: 400;
}
.Pricing__card {
  border-radius: 10px;
  box-shadow: 0px 15px 43px rgba(160, 168, 189, 0.3);
}
.Pricing__card__heading {
  color: var(--dark);
  font-family: "Red Hat Text";
  line-height: 35px;
  margin-bottom: 3px !important;
}
.Pricing__card__detail {
  color: var(--dark);
  font-family: "Red Hat Text";
  line-height: 22px;
}
.Pricing__card__detail--small {
  color: var(--main);
  font-family: "Red Hat Text";
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding-top: 4px !important;
}
.Pricing__card--h4 {
  margin: 0px;
  line-height: 1;
}
.Pricing__card__price {
  color: var(--main);
  font-family: "Red Hat Text";
  font-size: 30px;
}
.Pricing__card__price--small {
  color: var(--main);
  font-family: "Red Hat Text";
  font-weight: 600;
  font-size: 16px;
}
.Pricing__card--button {
  font-family: "Red Hat Text";
  outline: none;
  max-width: 250px !important;
  width: 100%;
  height: 45px;
  margin-top: 17px !important;
  border: 0px solid;
  background: var(--main) !important;
  border-radius: 5px;
  font-weight: 500;
}
.Pricing__card--button--Link {
  color: var(--light);
  font-family: "Red Hat Text";
}
.Pricing__card--button--Link:hover {
  color: var(--light);
}
.Pricing__include__heading {
  color: var(--dark);
  font-family: "Red Hat Text";
  font-weight: 900;
}
.Pricing__include__list__text {
  color: var(--dark);
  font-family: "Red Hat Text";
  font-size: 14.5px;
}

.Col {
  position: relative;
  min-height: 800px;
  max-width: 272px;
}

@media only screen and (max-width: 1199px) {
  .Col {
    min-height: 680px !important;
  }
}
@media only screen and (min-width: 1400px) {
  .Pricing-container {
    max-width: 1150px;
  }
}
@media only screen and (max-width: 1185px) {
  .Pricing-container {
    max-width: 866px;
  }
  .Col3 {
    margin-right: 0px !important;
  }
}
@media only screen and (max-width: 991px) {
  .Pricing__heading {
    line-height: 60px;
    font-size: 42px;
  }
  .Pricing__detail {
    font-size: 23px;
    line-height: 33px;
  }
}
@media only screen and (max-width: 913px) {
  .Pricing-container {
    max-width: 578px;
  }
}
@media only screen and (max-width: 690px) {
  .Pricing__heading {
    line-height: 50px;
    font-size: 40px;
  }
  .Pricing__detail {
    font-size: 22px;
    line-height: 32px;
  }
}
@media only screen and (max-width: 625px) {
  .Pricing-container {
    max-width: 285px;
  }
  .Col {
    min-height: auto !important;
  }
  .Col2 {
    margin-bottom: 40px !important;
  }
}
@media only screen and (max-width: 603px) {
  .Pricing__heading {
    line-height: 45px;
    font-size: 35px;
  }
  .Pricing__detail {
    font-size: 20px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 361px) {
  .Pricing__group__btn2 {
    min-height: 55px;
    height: auto;
  }
  .Pricing__group__btn1 {
    min-height: 55px;
    height: auto;
  }
}/*# sourceMappingURL=style.css.map */