@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap')
.Footer
    background: var(--light) !important
    &__para
        font-family: 'Red Hat Text'
    &__heading
        font-family: 'Red Hat Text'
    &__menu
        &__item
            &__link
                font-family: 'Red Hat Text'
                color: var(--dark)
                font-weight: 500
                &:hover
                    color: var(--main)
    &__container
        &--div
            width: 30px
            height: 30px
            cursor: pointer
            background: #1877F2
            border-radius: 5px
            transition: all 0.3s ease
            &__icon
                border-radius: 50%
                background: var(--light)
                color: #1877F2
            &:hover
                background: var(--secondary)
            &:hover .Footer__container--div__icon
                color: var(--secondary)
        &--div2
            background: #55ACEE
            &__icon2
                color: var(--light)
        &--div3
            background: #2764AC
            &__icon3
                color: var(--light)
        &--div4
            background: #C32AA3
            &__icon4
                color: var(--light)
        &--div5
            background: #BB0000
            &__icon5
                color: var(--light)
    &__copyright
        &__text
            font-family: 'Red Hat Text'
            color: var(--dark)
        &__list
            &__item
                &--Link
                    color: var(--dark)
                    &:hover
                        color: var(--dark)
