.Policy
    &__container
        max-width: 750px
        width: 100%
        color: var(--privacyText)
        font-family: 'Red Hat Text'
        &--h3
            font-size: 22px
        &--h6
            font-size: 15px
            line-height: 25px
        &__para
            font-size: 14px
            line-height: 20px
            &--Link
                text-decoration: none
                color: var(--secondary)
                &:hover
                    text-decoration: underline
                    color: var(--secondary)
        &__list
            &__item
                font-size: 14px
                line-height: 20px
                &--Link
                    color: var(--secondary)
                    &:hover
                        color: var(--secondary)
                &--bold
                    font-weight: 600
