.Team
    background: var(--grey)
    &__heading
        font-size: 30px
        font-family: 'Red Hat Text'
        font-weight: 600
    &__card
        max-width: 340px
        &--img
           max-width: 100% 
           user-select: none
        &--bg
            background: var(--light)
            box-shadow: 0px 15px 43px rgba(160,168,189,0.3) 
        &__content
            &__title1
                color: var(--main)
                font-family: 'Red Hat Text'
                font-size: 20px
                font-weight: 500
            &__title2
                color: #333333
                font-family: 'Red Hat Text'   
                font-size: 18px 
                font-weight: 400
@media only screen and (max-width: 767px)
    .Team__card__content__title
        // font-size: 13px 
    .Team__card--img
        max-width: 300px 
        width: 100%  
@media only screen and (max-width: 575px)
    .Team__card__content__title
        font-size: 19px 
    .Team__card--img
        max-width: 250px 
        width: 100%  
    .Team__heading
        line-height: 40px       
@media only screen and (max-width: 324px)    
    .Team__heading
        font-size: 27px    
@media only screen and (max-width: 298px)    
    .Team__heading
        font-size: 25px         

                 